import './index.scss';
import { useState } from 'react';
import axios from 'axios';

function FormView() {
    const [url, setUrl] = useState('https://');
    const [host, setHost] = useState('marc');
    const [language, setLanguage] = useState('UK');
    const [progress, setProgress] = useState(-1);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [latestStatus, setLatestStatus] = useState('');
    let interval = 0;
    let token = '';

    const handleUrlChange = (event) => {
        setUrl(event.target.value);
    };

    const handleHostChange = (host) => {
        setHost(host);
    };

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    /**
     * Create a new render
     */
    const handleRender = async () => {
        setLoading(true);
        setVideoUrl('');
        setErrorMessage('');
        setLatestStatus('');
        setProgress(0);
        setErrorMessage('');

        let result = await axios.post("https://engagement-newscaster-api-acc.lwdev.nl/api/newsvideo/save", {
            url,
            host,
            language
        });
        let data = result.data.data.data;
        token = data.token;

        interval = setInterval(handleStatus, 1000)
    }

    /** 
     * Get status
     */
    const handleStatus = async () => {
        let result = await axios.post("https://engagement-newscaster-api-acc.lwdev.nl/api/newsvideo/getStatus", { token: token });
        let data = result.data.data.data;
        if (data.status === 2) {
            setVideoUrl(data.outputUrl);
            setLatestStatus(0);
            clearInterval(interval);
            setLoading(false);
        }
        else if (data.status === 0) {
            setProgress(0);
            setLoading(false);
        }
        else if (data.status === -1) {
            setLoading(false);
            clearInterval(interval);
            setErrorMessage(data.error);
        }
        else {
            setProgress(data.progress);
            setLatestStatus(data.statusMessages[data.statusMessages.length - 1])
        }
    }

    return (
        <div className="form-view">
            <div className="form-view-container">

                <div className="form-view__sidebar">
                    <div className="form-view__logo"></div>

                    <div className="form-view__sidebar__form">
                        <div className="form-view__sidebar__form__field">
                            <div className="form-view__sidebar__form__field__label">
                                Article URL
                            </div>
                            <input type="text" placeholder="Article URL"
                                value={url}
                                onChange={handleUrlChange} />
                        </div>

                        <div className="form-view__sidebar__form__field">
                            <div className="form-view__sidebar__form__field__label">
                                Language
                            </div>
                            <select
                                value={language}
                                onChange={handleLanguageChange}>
                                <option value="NL">Dutch</option>
                                <option value="BE">Belgian</option>
                                <option value="UK">English (UK)</option>
                                <option value="US">English (US)</option>
                                <option value="FR">French</option>
                                <option value="DE">German</option>
                            </select>
                        </div>
                    </div>

                    <div className="form-view__sidebar__form__field">
                        <div className="form-view__sidebar__form__field__label">
                            Choose your host
                        </div>

                        <div className="form-view__sidebar__form__field__hosts">

                            <div onClick={() => handleHostChange('marc')} className={"form-view__sidebar__form__field__hosts__host form-view__sidebar__form__field__hosts__host4 " + (host === 'marc' ? 'form-view__sidebar__form__field__hosts__host--active' : '')} >
                                <div className="form-view__sidebar__form__field__hosts__host__image image4">

                                </div>
                                <div className="form-view__sidebar__form__field__hosts__host__name">
                                    Mark
                                </div>
                            </div>

                            <div onClick={() => handleHostChange('zoe')} className={"form-view__sidebar__form__field__hosts__host form-view__sidebar__form__field__hosts__host1 " + (host === 'zoe' ? 'form-view__sidebar__form__field__hosts__host--active' : '')} >
                                <div className="form-view__sidebar__form__field__hosts__host__image image1">

                                </div>
                                <div className="form-view__sidebar__form__field__hosts__host__name">
                                    Zoe
                                </div>
                            </div>

                            <div onClick={() => handleHostChange('mike')} className={"form-view__sidebar__form__field__hosts__host form-view__sidebar__form__field__hosts__host2 " + (host === 'mike' ? 'form-view__sidebar__form__field__hosts__host--active' : '')} >
                                <div className="form-view__sidebar__form__field__hosts__host__image image2">

                                </div>
                                <div className="form-view__sidebar__form__field__hosts__host__name">
                                    Mike
                                </div>
                            </div>

                            {(language === 'UK' || language === 'US') && (
                                <div onClick={() => handleHostChange('david')} className={"form-view__sidebar__form__field__hosts__host form-view__sidebar__form__field__hosts__host3 " + (host === 'david' ? 'form-view__sidebar__form__field__hosts__host--active' : '')} >
                                    <div className="form-view__sidebar__form__field__hosts__host__image image3">

                                    </div>
                                    <div className="form-view__sidebar__form__field__hosts__host__name">
                                        David
                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                    {loading && progress === 0 && (<div className="form-view__sidebar__loading">
                        <div class="loader"></div>
                    </div>)}
                    {!loading && progress === 0 && (<div className="form-view__sidebar__queue">
                        Waiting in queue..
                    </div>
                    )}
                    {loading && progress > 0 && (<div className="form-view__sidebar__loading__bar">
                        <div class="progress-bar">
                            <div class="progress-bar__fill" style={{ width: progress + '%' }}>{progress}%</div>
                        </div>
                    </div>)}

                    {errorMessage && (<div className="form-view__sidebar__error">{errorMessage}</div>)}

                    {!loading && progress !== 0 && <button onClick={handleRender} className="form-view__sidebar__button">GENERATE</button>}

                    <div className="livewall">
                        <a href="https://www.livewall.nl" target="_blank" rel="noreferrer">
                        </a>
                    </div>
                </div>

                <div className="form-view__video">
                    <div className="form-view__video__holder">
                        {latestStatus && !videoUrl && (<div className="form-view__video__status">
                            {latestStatus}
                        </div>)}

                        {videoUrl && (
                            <video controls autoPlay src={videoUrl}>
                            </video>
                        )}
                    </div>

                </div>

            </div>

        </div >
    );
}

export default FormView;
