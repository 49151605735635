import { useState } from 'react';
import './index.scss';
import { useNavigate } from "react-router-dom";

function IntroductionView({ }) {

    const navigate = useNavigate();


    const handleClick = () => {
        navigate("/form");
    }

    return (
        <div className="introduction-view">
            <div className='introduction-view__full-width'>
                <div className='introduction-view__topbar'>
                    <div className='introduction-view__logo'></div>
                </div>
                <div className='introduction-view__main'>
                    <div className='introduction-view__left'>
                        <div className="introduction-view__topline">Newscaster</div>
                        <h1>Your AI generated news video</h1>
                        <h2>Let us introduce NewsCaster, our playground to make your articles talk. Fresh from the AI playground of <a href="https://www.livewall.nl" target="_blank" rel="noreferrer">LiveWall</a></h2>
                        <div className="button" onClick={handleClick}>Start</div>
                    </div>
                    <div className='introduction-view__right'>

                    </div>

                </div>
            </div>
        </div>

    )
}

export default IntroductionView;